import React, { useCallback, useEffect, useRef, useState } from 'react';
import BackgroundSelectionDialog from '../BackgroundSelectionDialog/BackgroundSelectionDialog';
import ChatWindow from '../ChatWindow/ChatWindow';
import clsx from 'clsx';
import { GalleryView } from '../GalleryView/GalleryView';
import { MobileGalleryView } from '../MobileGalleryView/MobileGalleryView';
import MainParticipant from '../MainParticipant/MainParticipant';
import { makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { LocalAudioTrack, LocalVideoTrack, Participant, Room as IRoom } from 'twilio-video';
import { ParticipantAudioTracks } from '../ParticipantAudioTracks/ParticipantAudioTracks';
import ParticipantList from '../ParticipantList/ParticipantList';
import { useAppState } from '../../state';
import useChatContext from '../../hooks/useChatContext/useChatContext';
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useLocalVideoToggle from '../../hooks/useLocalVideoToggle/useLocalVideoToggle';
import useDevices from '../../hooks/useDevices/useDevices';

const useStyles = makeStyles((theme: Theme) => {
  const totalMobileSidebarHeight = `${theme.sidebarMobileHeight +
    theme.sidebarMobilePadding * 2 +
    theme.participantBorderWidth}px`;
  return {
    container: {
      position: 'relative',
      height: '100%',
      display: 'grid',
      gridTemplateColumns: `1fr ${theme.sidebarWidth}px`,
      gridTemplateRows: '100%',
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: `100%`,
        gridTemplateRows: `calc(100% - ${totalMobileSidebarHeight}) ${totalMobileSidebarHeight}`,
      },
    },
    rightDrawerOpen: { gridTemplateColumns: `1fr ${theme.sidebarWidth}px ${theme.rightDrawerWidth}px` },
  };
});

/**
 * This hook turns on speaker view when screensharing is active, regardless of if the
 * user was already using speaker view or gallery view. Once screensharing has ended, the user's
 * view will return to whatever they were using prior to screenshare starting.
 */

export function useSetSpeakerViewOnScreenShare(
  screenShareParticipant: Participant | undefined,
  room: IRoom | null,
  setIsGalleryViewActive: React.Dispatch<React.SetStateAction<boolean>>,
  isGalleryViewActive: boolean
) {
  const isGalleryViewActiveRef = useRef(isGalleryViewActive);

  // Save the user's view setting whenever they change to speaker view or gallery view:
  useEffect(() => {
    isGalleryViewActiveRef.current = isGalleryViewActive;
  }, [isGalleryViewActive]);

  useEffect(() => {
    if (screenShareParticipant && screenShareParticipant !== room!.localParticipant) {
      // When screensharing starts, save the user's previous view setting (speaker or gallery):
      const prevIsGalleryViewActive = isGalleryViewActiveRef.current;
      // Turn off gallery view so that the user can see the screen that is being shared:
      setIsGalleryViewActive(false);
      return () => {
        // If the user was using gallery view prior to screensharing, turn gallery view back on
        // once screensharing stops:
        if (prevIsGalleryViewActive) {
          setIsGalleryViewActive(prevIsGalleryViewActive);
        }
      };
    }
  }, [screenShareParticipant, setIsGalleryViewActive, room]);
}

export default function Room() {
  const classes = useStyles();
  const { isChatWindowOpen } = useChatContext();
  const { isBackgroundSelectionOpen, room, setWs, localTracks, isHost, isSharingScreen } = useVideoContext();
  const audioTrack = localTracks.find(track => track.kind === 'audio') as LocalAudioTrack;
  const videoTrack = localTracks.find(track => track.kind === 'video') as LocalVideoTrack;
  const { isGalleryViewActive, setIsGalleryViewActive } = useAppState();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const screenShareParticipant = useScreenShareParticipant();
  const localParticipant = room!.localParticipant;
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const { hasVideoInputDevices } = useDevices();
  // const [ws, setWs] = useState<WebSocket | null>(null);

  const min = 100000; // Smallest 6-digit number (100000)
  const max = 999999; // Largest 6-digit number (999999)
  const random6DigitNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  const system6DigitNumber = Math.floor(Math.random() * (max - min + 1)) + min;

  var client = {
      user_id: random6DigitNumber,
      sid: localParticipant.sid,
      isHost: isHost,
      system_id: ""+system6DigitNumber,
      recipient_id: null,
      type: 'socket',
      message: null
  };

  const toggleVideo = useCallback(() => {
    toggleVideoEnabled();
  }, [toggleVideoEnabled]);

  useEffect(() => {
    const webSocket = new WebSocket('wss://callsocket.virtualpharmacist.co.uk:8081/');
    // WebSocket onopen event
    webSocket.onopen = () => {
      console.log("open");
      client.recipient_id = null;
      client.type = "init";
      client.message = null;
      webSocket.send(JSON.stringify(client));
      console.log('WebSocket connected');
      // You can perform actions when the WebSocket connection is established here
    };
    
    // WebSocket onmessage event
    webSocket.onmessage = (event) => {
      console.log('WebSocket message received:', event.data);
      // Handle incoming WebSocket messages here
      var data = JSON.parse(event.data);
      if (data.type === "mute_audio") {
        audioTrack.disable();
      } else if (data.type === "unmute_audio") {
        audioTrack.enable();
      } else if (data.type === "mute_video") {
        console.log(localParticipant.videoTracks);
        if (localParticipant.videoTracks.size > 0) {
          eval(`document.getElementsByClassName("stop_screen_share").length > 0 ? document.getElementsByClassName("stop_screen_share")[0].click() : ''`);
          eval('document.getElementsByClassName("video_toggle_btn")[0].click();');
        }
      } else if (data.type === "unmute_video") {
        console.log("isSharingScreen : ", isSharingScreen);
        console.log(localParticipant.tracks);
        console.log(localParticipant.videoTracks);
        eval(`document.getElementsByClassName("stop_screen_share").length > 0 ? document.getElementsByClassName("stop_screen_share")[0].click() : ''`);
        if (localParticipant.videoTracks.size <= 0) {
          eval('document.getElementsByClassName("video_toggle_btn")[0].click();');
        }
      } else if (data.type === 'mute_screen') {
        eval(`document.getElementsByClassName("stop_screen_share").length > 0 ? document.getElementsByClassName("stop_screen_share")[0].click() : ''`);
      }
    };

    // WebSocket onclose event
    webSocket.onerror = (event) => {
      alert("Fail to connect");
      room?.disconnect();
    }
    webSocket.onclose = (event) => {
      console.log('WebSocket connection closed:', event);
      // You can handle WebSocket connection closure here, and possibly attempt to reconnect
    };

    // Store the WebSocket instance in state
    setWs(webSocket);

    return () => {
      // Close the WebSocket connection when the component unmounts
      webSocket.close();
    };
  }, []);

  // Here we switch to speaker view when a participant starts sharing their screen, but
  // the user is still free to switch back to gallery view.
  useSetSpeakerViewOnScreenShare(screenShareParticipant, room, setIsGalleryViewActive, isGalleryViewActive);

  return (
    <div
      className={clsx(classes.container, {
        [classes.rightDrawerOpen]: isChatWindowOpen || isBackgroundSelectionOpen,
      })}
    >
      {/* 
        This ParticipantAudioTracks component will render the audio track for all participants in the room.
        It is in a separate component so that the audio tracks will always be rendered, and that they will never be 
        unnecessarily unmounted/mounted as the user switches between Gallery View and speaker View.
      */}
      <ParticipantAudioTracks />

      {isGalleryViewActive ? (
        isMobile ? (
          <MobileGalleryView />
        ) : (
          <GalleryView />
        )
      ) : (
        <>
          <MainParticipant />
          <ParticipantList />
        </>
      )}

      <ChatWindow />
      <BackgroundSelectionDialog />
    </div>
  );
}
