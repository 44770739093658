import { makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { Participant } from 'twilio-video';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';


const useStyles = makeStyles((theme: Theme) => ({
    container: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
    },
    identity: {
      background: 'rgba(0, 0, 0, 0.5)',
      color: 'white',
      padding: '0.1em 0.3em 0.1em 0',
      display: 'inline-flex',
      '& svg': {
        marginLeft: '0.3em',
      },
      marginRight: '0.4em',
      alignItems: 'center',
    },
    infoContainer: {
      position: 'absolute',
      zIndex: 2,
      height: '100%',
      width: '100%',
    },
    reconnectingContainer: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(40, 42, 43, 0.75)',
      zIndex: 1,
    },
    fullWidth: {
      gridArea: '1 / 1 / 2 / 3',
      [theme.breakpoints.down('sm')]: {
        gridArea: '1 / 1 / 3 / 3',
      },
    },
    avatarContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'black',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1,
      '& svg': {
        transform: 'scale(2)',
      },
    },
    recordingIndicator: {
      position: 'absolute',
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      background: 'rgba(0, 0, 0, 0.5)',
      color: 'white',
      padding: '0.1em 0.3em 0.1em 0',
      fontSize: '1.2rem',
      height: '28px',
      [theme.breakpoints.down('sm')]: {
        bottom: 'auto',
        right: 0,
        top: 0,
      },
    },
    circle: {
      height: '12px',
      width: '12px',
      background: 'red',
      borderRadius: '100%',
      margin: '0 0.6em',
      animation: `1.25s $pulsate ease-out infinite`,
    },
    '@keyframes pulsate': {
      '0%': {
        background: `#A90000`,
      },
      '50%': {
        background: '#f00',
      },
      '100%': {
        background: '#A90000',
      },
    },
}));

const { REACT_APP_TWILIO_BACKEND_API } = process.env;

export default function KickUser({participant}:{ participant: Participant }) {

    const { room } = useVideoContext();
    var dispName;
    var participantIsHost = false;
    if (participant.identity !== "") {
        dispName = JSON.parse(participant.identity);
        participantIsHost = dispName.is_host;
        dispName = dispName.name;
    } else {
        dispName = participant.identity;
    }
    const classes = useStyles();

    const kickParticipant = () => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-API-KEY': '1234'
            },
            body: JSON.stringify({room_id:room?.sid, sid:participant.sid})
        };
        console.log("participant.sid", participant.sid);
        console.log("participants : ", room?.sid);
        fetch(REACT_APP_TWILIO_BACKEND_API+'kick_user', requestOptions).then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json(); // Parse the response as JSON
        }).then(data => {
            // Handle the JSON response data
            console.log(data);
        })
        .catch(error => {
            // Handle errors
            console.error('There was a problem with the fetch operation:', error);
        });
    }

    return (
        <>
            {!participantIsHost ? (
                <div onClick={kickParticipant} className={classes.identity} style={{ marginLeft: "10px", paddingLeft: "10px", paddingRight: "10px", cursor: "pointer" }}>
                    <Typography variant="body1" color="inherit">
                    Kick User
                    </Typography>
                </div>
                ) : (<></>)
            }
        </>
    ); 
}