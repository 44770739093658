import React, { useState, useEffect, FormEvent } from 'react';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import IntroContainer from '../IntroContainer/IntroContainer';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
import RoomNameScreen from './RoomNameScreen/RoomNameScreen';
import { useAppState } from '../../state';
import { useParams } from 'react-router-dom';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';

export enum Steps {
    roomNameStep,
    deviceSelectionStep,
}
const { REACT_APP_TWILIO_BACKEND_API } = process.env;

export default function PreJoinScreens() {

    const { user } = useAppState();
    const { getAudioAndVideoTracks, setIsHost, onError } = useVideoContext();
    const { token } = useParams<{ token?: string }>();
    // const { URLRoomName } = useParams<{ URLRoomName?: string }>();
    // const { URLName } = useParams<{ URLName?: string }>();
    // const { isHost } = useParams<{ isHost?: string }>();
    const [step, setStep] = useState(Steps.deviceSelectionStep);

    const [loadingText, setLoadingText] = useState<string>('Loading...');
    const [host, setHost] = useState<boolean>(false);
    const [name, setName] = useState<string>(user?.displayName || '');
    const [roomName, setRoomName] = useState<string>('');

    const [mediaError, setMediaError] = useState<Error>();

    useEffect(() => {

        const fatchInformation = async () => {
            try {
                const endpoint = REACT_APP_TWILIO_BACKEND_API + 'get_user_info' || '';
                const response = await fetch(endpoint, {
                    method: 'POST',
                    headers: {
                        'x-api-key': '1234',
                        'content-type': 'application/json',
                    },
                    body: JSON.stringify({
                        token: token,
                    }),
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                    setLoadingText('Network response was not ok');
                }
                const responseData = await response.json();
                console.log(responseData);
                if (responseData.error === 0) {
                    if (responseData.is_host === true) {
                        setHost(true);
                        setIsHost(true);
                    } else {
                        setHost(false);
                        setIsHost(false);
                    }
                    const idt = JSON.stringify({ name: responseData.name, is_host: responseData.is_host });
                    setName(idt);
                    setRoomName(responseData.room_name);
                } else {
                    setLoadingText(responseData.message);
                    onError(new Error("error: "+responseData.message));
                    console.error('Error fetching data:', responseData.message);
                }
            } catch (error) {
                setLoadingText(""+error);
                onError(new Error("Error fetching data: "+error));
                console.error('Error fetching data:', error);
            }
        }
        fatchInformation();
    }, [user]);
    // }, [user, URLRoomName, URLName, isHost]);

    useEffect(() => {
        if (step === Steps.deviceSelectionStep && !mediaError) {
            getAudioAndVideoTracks().catch(error => {
                console.log('Error acquiring local media:');
                console.dir(error);
                setMediaError(error);
            });
        }
    }, [getAudioAndVideoTracks, step, mediaError]);

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // If this app is deployed as a twilio function, don't change the URL because routing isn't supported.
        // @ts-ignore
        if (!window.location.origin.includes('twil.io') && !window.STORYBOOK_ENV) {
            window.history.replaceState(null, '', window.encodeURI(`/room/${roomName}${window.location.search || ''}`));
        }
        setStep(Steps.deviceSelectionStep);
    };

    return (
        <IntroContainer>
            <MediaErrorSnackbar error={mediaError} />
            {step === Steps.roomNameStep && (
                <RoomNameScreen
                    name={name}
                    roomName={roomName}
                    setName={setName}
                    setRoomName={setRoomName}
                    handleSubmit={handleSubmit}
                />
            )}

            {step === Steps.deviceSelectionStep && name !== "" && name !== null ? (
                <DeviceSelectionScreen name={name} roomName={roomName} setStep={setStep} />
            ) : ( token && token !== null && token !== "" ? (<h1>{loadingText}</h1>) : (<h2>Invalid information</h2>) ) }
        </IntroContainer>
    );
}
